import { FaExclamation } from 'react-icons/fa';
import { MdLocalPharmacy } from 'react-icons/md';
import { CgPill } from 'react-icons/cg';
import { GoLaw } from 'react-icons/go';
import { GiCardboardBoxClosed, GiMicrophone } from "react-icons/gi";
import { BsBriefcaseFill, BsFillCheckSquareFill } from 'react-icons/bs';

const services = [
  {
    title: 'Medication Diversion',
    icon: MdLocalPharmacy,
    description:
    `We provide gap assessments to identify medication diversion risks/potential throughout your facility 
    and will provide risk mitigation strategies to combat such risks. We can also help develop a Medication 
    Diversion Program if needed and/or review your existing Program.`
  },

  {
    title: 'Medication Compounding (USP 795, 797 and 800 Compliance)',
    icon: FaExclamation,
    description:
    `We provide gap assessments and action plans to healthcare entities regarding medication 
    compounding compliance to USP standards. We can help create a compounding program based 
    on your needs as well as review any remodeling plans that were created by an architectural 
    firm to ensure compliance to USP standards.  We can help design or redesign your current compounding space. `
  },
  {
    title: 'Medication Safety',
    icon: CgPill,
    description:
    `We provide gap assessments and action plans to healthcare entities regarding medication safety as it relates to one’s internal policies and ISMP standards`
  },
  {
    title: 'Healthcare Fraud Investigator',
    icon: BsBriefcaseFill,
    description:
    `We plan and execute investigations of acts of healthcare fraud and abuse.`
  },
  {
    title: 'Correctional Facilities',
    icon: BsFillCheckSquareFill,
    description:
    `We provide nursing and physician education on proper use of formulary medications. 
    We also provide audits and action plans as it relates to the facility’s internal, 
    state and federal regulations regarding medications.`
  },
  {
    title: 'Physician Practice Medication Management',
    icon: GoLaw,
    description:
    `We audit onsite medication management storage areas and inventory 
    records to ensure compliance with local and state laws.`
  },
  {
    title: 'Pharmacy Central Distribution',
    icon: GiCardboardBoxClosed,
    description:
    `We provide assistance for those looking to set up Central Pharmacy Distribution (standalone or within an existing space) 
    to their existing client facilities.`
  },
  {
    title: 'Speaking Engagements',
    icon: GiMicrophone,
    description:
    `We are also available for speaking engagements. Being able to provide guidance and leadership at medical facilities, universities, 
    and conferences better helps us all reach our goals by paying forward our experiences.`
  }
];
  
  export { services };