import oshlogo from '../assets/oshlogo.png';
import oshlogo2 from '../assets/oshlogo2.png';
import oshlogo3 from '../assets/oshlogo3.png';
import oshlogotrans from '../assets/oshlogotrans.png';
import oshlogotrans2 from '../assets/oshlogotrans2.png';
import header from '../assets/header.png';
import pharmacy_icon1 from '../assets/pharmacy_icon1.png';
import pharmacy_icon2 from '../assets/pharmacy_icon2.png';
import pharmacist from '../assets/pharmacist.png';
import logo2 from '../assets/logo2.png';
import meds from '../assets/meds.png';
import meds2 from '../assets/meds2.png';
import meds3 from '../assets/meds3.png';
import newsletter from '../assets/newsletter.png';
import hblogo from '../assets/hblogo.png';
import email from '../assets/email.png';
import bgart from '../assets/bgart.png';
import user2 from '../assets/user2.png';
import lab from '../assets/lab.jpg';
import sherone from '../assets/sherone.jpg';

export default {
    oshlogo,
    header,
    pharmacy_icon1,
    pharmacy_icon2,
    pharmacist,
    logo2,
    oshlogo2,
    oshlogo3,
    oshlogotrans,
    oshlogotrans2,
    meds,
    meds2,
    meds3,
    newsletter,
    hblogo,
    email,
    bgart,
    user2,
    lab,
    sherone,
}