import React from 'react';
import './About.scss';
import { accolades } from './accolades';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { BsArrowRightSquare } from 'react-icons/bs';

const About = () => {
  return (
    <>
      {/* <div className='app_about' id='about' /> */}
      {/* <motion.div className='app_about_header'>
        {accolades.map((accolade, index) => (
          <div className='app_accolades'>
            <h3 className='accolades_value'>{accolade.value}</h3>
            <p className='accolades_title'>{accolade.title}</p>
          </div>
        ))}
      </motion.div> */}

      <div className='app_about_body' id='about'>
        <div className='body_img'>
          <img src={images.lab} alt={images.lab} />
        </div>
        <div className='app_about_container'> 
          <div className='app_about_content'>
              <div className='content_tag'>
                <h3 className='tag_title'>About Me</h3>
              </div>
            <div className='tag_header'>
              <img src={images.sherone} alt="My photo" />
              <h2>Sherone Ruggs Hubbard</h2>
              <h4>Licensed Pharmacist & Consultant</h4>
            </div>
            <h2 className='content_title'>A bit about me...</h2>
            <p className='body_content'>I am a Pharmacist with over 15 years of experience in Pharmacy operations, regulatory compliance and 
            medication safety in medium & large healthcare organizations. My goal is to provide expertise in the areas of pharmacy operations, 
            medication diversion, medication compounding and medication safety to ensure patient, employee and community safety.
            </p>
            <div className='schedule_button'>
              <a href="https://www.honeybook.com/widget/osh_consulting_llc_254683/cf_id/6382cc867a4f0822e9c47486" >
                Schedule a Session 
                <BsArrowRightSquare />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
