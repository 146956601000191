import React from 'react';
import { Navbar, Header, Blog, About, Services, Feedback, Footer } from '../components';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <About />
      <Services />
      <Blog />
      <Feedback />
      <Footer />
    </div>
  )
}

export default HomePage;
