import React, { useState } from 'react';
import { images } from '../../constants';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { GrLinkedinOption } from 'react-icons/gr';
import { TfiEmail } from 'react-icons/tfi';
// import { RxDividerVertical } from 'react-icons/rx';
import { motion } from 'framer-motion';
import './Navbar.scss';


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <>
      <div className='app_navbar'>
        <div className='navbar_img'>
          <img src={images.oshlogotrans} alt='logo' />
          <p>OSH Pharmaceutical Consulting</p>
        </div>
        <ul className='navbar_items'>
          <li><a href='/'>Home</a></li>
          <li><a href='/#about'>About Me</a></li>
          <li><a href='/#blog'>Blog</a></li>
          <li><a href='/#feedback'>Feedback</a></li>
          <li><a href='/#services'>Services</a></li>
          <li><a href='/#footer'>Contact</a></li>
        </ul>
        <hr />
        <div className='navbar_socials'>
          <a href="https://www.linkedin.com/in/sherone-ruggs-hubbard-04006b1b5/">
            <GrLinkedinOption className='linkedin' />
          </a>
          <a href="mailto:info@oshconsultingservices.com">
            <TfiEmail className='facebook' />
          </a>
        </div>
      </div>
      
      <div className='app_menu'>
        <div className='menu_img'>
          <div className='img-container'>
            <img src={images.oshlogotrans2} alt='logo' />
          </div>
          <div className='icon-container'>
            <HiMenuAlt4 className='overlay_open' onClick={() => setToggleMenu(true)} />
          </div>
        </div>
          {toggleMenu && (
            <motion.div
              className='menu_overlay'
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: 'easeOut' }}
            >
              <HiX className='overlay_close' onClick={() => setToggleMenu(false)} />
              <div className='menu_container'>
                <ul className='menu_items'>
                  <li><a href='/'>Home</a></li>
                  <li><a href='#about'>About Me</a></li>
                  <li><a href='#Blog'>How can I Help You?</a></li>
                  <li><a href='#feedback'>Feedback</a></li>
                  <li><a href='#blog'>Blog</a></li>
                  <li><a href='#footer'>Contact</a></li>
                </ul>
                <hr />
                <div className='menu_socials'>
                  <a href="https://www.linkedin.com/in/sherone-ruggs-hubbard-04006b1b5/">
                    <GrLinkedinOption className='linkedin' />
                  </a>
                  <a href="mailto:oshconsultingllc@gmail.com">
                    <TfiEmail className='facebook' />
                  </a>
                  <p>OSH Pharmaceutical Consulting</p>
                </div>
              </div>
            </motion.div>
          )}
      </div>
    </>
  )
}

export default Navbar
