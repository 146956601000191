import { React, useState, useEffect } from 'react';
import './Blog.scss';
import { BsArrowRightSquare } from 'react-icons/bs';
import { FaRegNewspaper } from "react-icons/fa";
import { images } from '../../constants';
import { urlFor, client } from '../../utils/client';
import { PortableText } from '@portabletext/react';
import axios from 'axios';


const Blog = () => {
  const [items, setItems] = useState([]);
  const [myPosts, setMyPosts] = useState([]);
  const key = 'cfc8e123088ae4bd462777f7ddee1b7e';
  const url = `https://gnews.io/api/v4/search?q=pharmacy&lang=en&country=us&max=10&apikey=${key}`;
  // const websiteUrl = item.url;
  // const website = websiteUrl.split('https://').pop().split('/')[0];
  // const date = item.publishedAt;
  // const formatDate = date.replace('T', ' ')
  // const formatTime = formatDate.replace('Z', '');


  // Needs to be passed as an environment variable
  useEffect(() => {
    const query = '*[_type == "blog"]';
    //axios.get(url, { headers: { 'X-ACCESS-KEY': process.env.API_KEY } })
    client.fetch(query).then((data) => {
      setMyPosts(data);
    })

     // Fetch news articles from GNews API
    axios.get(url)
    .then((res) => {
      // Check the structure of the response data from the API
      // In this case, assuming the response contains an array of articles in `articles` field
      if (res.data.articles) {
        setItems(res.data.articles);
      } else {
        console.error('Invalid response from GNews API:', res.data);
      }
    })
    .catch((err) => {
      console.error('Error fetching news articles:', err);
    });
  }, [])

  const MyPosts = () => {
    
    // useEffect(() => {  
    //   const query = '*[_type == "blog"]';
    
    //   client.fetch(query).then((data) => {
    //     setMyPosts(data);
    //   })
    // }, []);

    const formatDateTime = (dateTimeString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
      const formattedDateTime = new Date(dateTimeString).toLocaleDateString(undefined, options);
      return formattedDateTime;
    };

    return (
      <>
        {myPosts.length > 0 && (
          <div className='app_blog_personal'>
            <div className='myPosts_header'>
              <h1 className='myPosts_header'>Hear from me!</h1>
            </div>
            {myPosts.map((post) => (
              <div className='post-item'>
                <div className='post-icon'>
                  <img 
                    src={images.sherone} 
                    alt="Author"
                    className='post-img' 
                  />
                </div>
                <h3 className='post-title'>{post.title}</h3>
                <p className='post-date'>
                  Published: {formatDateTime(post.publishedAt)}
                </p>
                <div className='post-content'>
                  <p className='content'><PortableText value={post.content} /></p>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  
// To limit the data returned, use the slice method and specify a selected index such as (0, 10) and then use filter to only 
// grab the english content and combine it with .map function. So for example, items.slice(0,3).filter(site_language = 'en').map(())

  return (
    <div className='app_blog' id='blog'>
        <div className='app_blog_banner'>
            <div className='blog_tag'>
                <h3 className='blog_tag_title'>
                    Blog
                </h3>
            </div>
            <h1 className='blog_title'>
              What's going around the world?
            </h1>
        </div>
        <div className='app_blog_container'>
            {items.map((item) => (
              <div className='blog_item'>
                <div className='blog_icon'>
                  <FaRegNewspaper
                    style={{
                       width: '50', 
                       height: '50', 
                       color: '#537FE7' 
                      }} 
                  />
                  {/* <img 
                    src={item.image_url} 
                    alt={item.image_url ? item.title : 'Image not available'}
                  />  */}
                </div>
                <h3 className='blog_title'>{item.title}</h3>
                <p className='blog_pubdate'>
                  Date Published: {item.pubDate}
                </p>
                {/* <p className='blog_country'>
                  Country: {item.country}
                </p> */}
                <div className='schedule_button'>
                  <a href={item.link} >
                    Learn More 
                    <BsArrowRightSquare />
                  </a>
                </div>
              </div>
            ))}
            <div className='app_myPosts_section'>
              <MyPosts />
            </div>
        </div>
    </div>
  )
}

export default Blog;
