import './index.scss';
// import { Navbar, Header, Services, Footer, About, Blog, Feedback, } from './components';
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import { Route, Routes } from 'react-router-dom';


const App = () => {
  return (
    <div className="App">
      {/* <Navbar /> */}
        {/* <Header />
        <About />
        <Services />
        <Blog />
        <Feedback /> */}
      <Routes>
        <Route path="/" element={<HomePage />} /> 
        <Route path="/terms" element={<TermsPage />} /> 
        <Route path="/privacy" element={<PrivacyPage />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  )
}
export default App;
