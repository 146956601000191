import React from 'react';
import { Terms, Navbar } from '../components';

const TermsPage = () => {
  return (
    <div>
      <Navbar />
      <Terms />
    </div>
  )
}

export default TermsPage
