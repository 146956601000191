import React from 'react';
import { Privacy, Navbar } from '../components';

const PrivacyPage = () => {
  return (
    <div>
      <Navbar />
      <Privacy />
    </div>
  )
}

export default PrivacyPage
