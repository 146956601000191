export const terms = [
  {
    title: "1. Introduction",
    description: `These terms and conditions govern the provision of pharmacy consulting services by 
    OSH Consulting, LLC ("Consultant") to the client ("Yourself"). By engaging Consultant's services, Client 
    agrees to be bound by these terms and conditions.`
  },
  {
    title: "2. Services",
    description: `Consultant agrees to provide pharmacy consulting services as agreed upon with the Client. These services may 
    include, but are not limited to, pharmacy operations analysis, staff training, regulatory compliance assistance, and workflow optimization.`
  },
  {
    title: "3. Fees and Payment",
    description: `Client agrees to pay Consultant the fees as outlined in the agreement between the parties. Fees are due within [insert payment terms,
    e.g., 30 days] of the invoice date. Late payments may be subject to interest charges at a rate of [insert interest rate]% per month.`
  },
  {
    title: "4. Confidentiality",
    description: `Both parties agree to maintain the confidentiality of any proprietary or sensitive information shared during the course of the consulting 
    engagement. This includes, but is not limited to, patient data, business strategies, and trade secrets.`
  },
  {
    title: "5. Ownership of Work Product",
    description: `Any materials, reports, or recommendations produced by Consultant during the engagement shall remain the property of Consultant unless otherwise agreed upon in writing. 
    Client may use these materials solely for their internal purposes and may not distribute or reproduce them without Consultant's consent.`
  },
  {
    title: "6. Limitation of Liability",
    description: `Consultant shall not be liable to Client for any indirect, incidental, consequential, or punitive damages arising out of or relating to the consulting services provided, even if Consultant has been advised of 
    the possibility of such damages. In no event shall Consultant's total liability exceed the total fees paid by Client for the services rendered.`
  },
  {
    title: "7. Termination",
    description: `Either party may terminate the consulting engagement upon written notice to the other party. 
    In the event of termination, Client shall pay Consultant for all services rendered up to the date of termination.`
  },
  {
    title: "8. Governing Law",
    description: `These terms and conditions shall be governed by and construed in accordance with the laws of the state of Georgia. Any disputes arising out of or relating to 
    these terms and conditions shall be subject to the exclusive jurisdiction of the courts in the state of Georgia.`
  },
  {
    title: "9. Entire Agreement",
    description: `These terms and conditions constitute the entire agreement between Consultant and Client with respect to the subject matter hereof and supersede 
    all prior and contemporaneous agreements and understandings, whether oral or written.

    By engaging Consultant's services, Client acknowledges that they have read, understood, and agree to abide by these terms and conditions.`
  },
];

export const privacy = [
  {
    title: 'Privacy Policy',
    description: `OSH Consulting, LLC is committed to protecting the privacy and confidentiality of personal and health information entrusted
    to us by our clients and website visitors. This privacy policy outlines how we collect, use, disclose, and protect this information in accordance 
    with applicable laws, including the Health Insurance Portability and Accountability Act (HIPAA).`
  },
  {
    title: 'Information We Collect',
    description: `We may collect personal and health information from clients and website visitors in order to provide pharmacy consulting services. This information may include:`,
    points: [
      "• Name, address, and contact information",
      "• Medical history and current health conditions",
      "• Prescription and medication information",
      "• Financial information for billing purposes"
    ]
  },
  {
    title: 'How We Use Information',
    description: "We use the information we collect for the following purposes: ",
    points: [
      "• Providing pharmacy consulting services to our clients",
      "• Processing payments and billing",
      "• Communicating with clients about their healthcare needs",
      "• Improving our services and website functionality"
    ]
  },
  {
    title: 'Disclosure of Information',
    description: "We may disclose personal and health information in the following circumstances: ",
    points: [
      "• With client consent",
      "• As required by law or legal process",
      "• To authorized third-party service providers who assist us in providing services",
      "• In the event of a business merger, acquisition, or sale"
    ]
  },
  {
    title: 'Data Security',
    description: `We take reasonable measures to protect personal and health information from unauthorized access, 
    disclosure, alteration, or destruction. These measures include encryption, access controls, and regular security assessments.`
  },
  {
    title: 'HIPAA Compliance',
    description: `We are committed to complying with the Health Insurance Portability and Accountability Act (HIPAA) and other 
    applicable privacy laws. This includes safeguarding protected health information (PHI) and adhering to HIPAA's privacy and security standards.`
  },
  {
    title: 'Your Rights',
    description: "Clients and website visitors have the right to: ",
    points: [
      "• Access their personal and health information",
      "• Request corrections to inaccurate or incomplete information",
      "• Request restrictions on how their information is used and disclosed",
      "• Request a copy of this privacy policy"
    ]
  },
  {
    title: 'Contact Us',
    description: `If you have any questions or concerns about our privacy practices or this policy, please contact us at [Your Contact Information].`
  },
  {
    title: 'Changes to This Policy',
    description: `We reserve the right to update this privacy policy as needed to reflect changes in our practices or legal requirements. 
    We encourage you to review this policy periodically for any updates.`
  },
  {
    lastUpdated: 'February 2, 2024'
  }
]