import React from 'react';
import './Services.scss';
import { services } from './services';
import { BsArrowRightSquare } from 'react-icons/bs';
import { images } from '../../constants';


const Services = () => {
  return (
    <div className='app_services' id='services'>
        <div className='app_services_banner'>
            <div className='services_tag'>
                <h3 className='services_tag_title'>
                    Services
                </h3>
            </div>
            <h1 className='services_title'>
              This is a results driven business that pride ourselves on <br /> helping our clients grow.
            </h1>
        </div>
        <div className='app_services_container'>
            {services.map((service, i) => {
                const Icon = service.icon;
                return (
                <div className='service_item'>
                    <div className='service_icon'>
                        <Icon style={{ width: '50', height: '50', color: 'grey' }} />
                    </div>
                    <h3 className='services_title'>{service.title}</h3>
                    <p className='description'>{service.description}</p>
                    <div className='schedule_button'>
                      <a href="#footer">
                        Learn More 
                        <BsArrowRightSquare />
                      </a>
                    </div>
                </div>
                );
            })}
        </div>
    </div>
  )
}

export default Services;
