import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Terms from '../Terms/Terms';
import Privacy from '../Privacy/Privacy';
// import { Link } from 'react-router-dom';
import './Footer.scss';
import { images } from '../../constants';
import { GrLinkedinOption } from 'react-icons/gr';
import { TfiEmail } from 'react-icons/tfi';
import { BsArrowUpRight } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='app_contact' id='footer'>

      <div className='app_contact_nav'>
        <div className='logo_container'>
          <img src={images.oshlogotrans} alt='logo' />
          <h3 className='contact_nav_header'>
            OSH Pharmaceutical Consulting
          </h3>
        </div>

        <div className='items_container'>
          <ul className='nav_items'>
            <li><a href='#home'>Home</a></li>
            <li><a href='#about'>About Me</a></li>
            <li><a href='#blog'>Blog</a></li>
            <li><a href='#feedback'>Feedback</a></li>
            <li><a href='#services'>Services</a></li>
            <li><a href='#footer'>Contact</a></li>
          </ul>
        </div>
      </div>

      <div className='app_contact_container'>
        <ul className='contact_items'>
          <li><a href="https://dotcom.hbportal.co/app/">Current Clients Access Your Portal Here</a></li>
          <li><a href="mailto:oshconsultingllc@gmail.com">Something else? Email me <TfiEmail /></a></li>  
          {/* <li><a href="https://www.linkedin.com/in/sherone-ruggs-hubbard-04006b1b5/">Facebook <FaFacebookF /></a></li> 
          <li><a href="https://www.linkedin.com/in/sherone-ruggs-hubbard-04006b1b5/">Instagram <FaInstagram /></a></li> */} 
          <li><a href="https://www.linkedin.com/in/sherone-ruggs-hubbard-04006b1b5/">LinkedIn <GrLinkedinOption /></a></li>
          <li><a href="/terms">Terms & Conditions</a></li>
          <li><a href="/privacy">Privacy Policy</a></li>
          <li className='copyright'>© 2024 OSH Pharmaceutical Consulting, LLC.</li>
          <li className='design_tag'>Designed by: <a href="https://www.dotcomdv.com">dotcom</a></li>
        </ul>
      </div>

      <div className='app_contact_banner'>
        <h1 className='app_contact_header'><a href="https://www.honeybook.com/widget/osh_consulting_llc_254683/cf_id/6382cc867a4f0822e9c47486">Let's Chat!</a></h1>
        <a href="https://www.honeybook.com/widget/osh_consulting_llc_254683/cf_id/6382cc867a4f0822e9c47486"><BsArrowUpRight /></a>
      </div>

    </div>
  )
}

export default Footer
