import React from 'react';
import './Terms.scss';
import { terms } from '../../constants/legal';

const Terms = () => {
  return (
    <div className='app_terms'>
      <div className='terms-header'>
        <h1 className='app_terms_header'>Terms & Conditions</h1>
      </div>
      {terms.map((term, i) => {
        return (
          <div className='terms-body'>
            <h3 className='term-title'>{term.title}</h3>
            <p className='term-title'>{term.description}</p>
          </div>
        )
      })}
      
    </div>
  )
}

export default Terms
