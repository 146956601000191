import React from 'react';
import HeaderOverlay from './HeaderOverlay';
import { images } from '../../constants';
import { motion } from 'framer-motion';
import { slideIn, textVariant } from '../../utils/motion';
import './Header.scss';
import { BiMessageCheck } from 'react-icons/bi';

const Header = () => {
  return (
    <motion.div 
      className='app_header' 
      id='home'
    >
      <div className='app_header_banner'>
        <div className='app_header_banner_body'>
          <div className='banner-text'>
            <div className='banner-header'>
              <h1 className='top'>"Guidance you can trust,</h1>
              <h1 className='bottom'>Results you deserve"</h1>
            </div>
            <p className='desc'>
              Expertise is what we have. 
              <br /> Compliance and high performance is who we are. 
              <br /> Delivering results is what we do. 
            </p>
          </div>

          <div className='app_header_img'>
            <img src={images.oshlogo2} alt='OSH Logo'/>
          </div>

        </div>

        {/* <div className='app_header_banner_invite'>
          <h3 className='subtitle-text'>Heard enough? Let's Chat.</h3>
          <div className='contact-btn'>
            <a href="#footer">Contact Me</a>
          </div>
        </div>  */}
      </div>

      {/* <div className='app_header_img'>
        <img src={images.oshlogo2} alt='OSH Logo'/> */}
        {/* <div className='app_header_comment'>
            <h1 className='subtitle-text'>Client comments</h1>
            <h2 className='subtitle-text'>98% Positive Feedback</h2>
            <div className='percent'>
                <div className='percent_bubble'>
                    <p>100%</p>
                </div>
                <p>Coaching Efficiency</p>
            </div>
        </div> */}
      {/* </div> */}
      
    </motion.div>
  )
}

export default Header
