import React from 'react';
import './Privacy.scss';
import { privacy } from '../../constants/legal';

const Privacy = () => {
  return (
    <div className='app_terms'>
      <div className='privacy-header'>
        <h1 className='app_terms_header'>Privacy Policy</h1>
      </div>
      {privacy.map((object, i) => {
        return (
          <div className='privacy-body'>
            <h3 className='term-title'>{object.title}</h3>
            <p className='term-title'>{object.description}</p>
          </div>
        )
      })}
      
    </div>
  )
}

export default Privacy
